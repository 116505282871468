// extracted by mini-css-extract-plugin
export var card = "categoryCard-module--card--YeR1y";
export var clipItem = "categoryCard-module--clip-item--29gyq";
export var clipItemText = "categoryCard-module--clip-item-text--9SKam";
export var clips = "categoryCard-module--clips--KNbgk";
export var header = "categoryCard-module--header--eP-Hr";
export var headerIcon = "categoryCard-module--header-icon--wlu+N";
export var headerTitle = "categoryCard-module--header-title--jYxp2";
export var image = "categoryCard-module--image--Uo8Qo";
export var link = "categoryCard-module--link--QyZ8s";
export var topicItem = "categoryCard-module--topic-item--OBXb+";
export var topics = "categoryCard-module--topics--oom6K";